import React from 'react'
import { useCallback } from 'react'
import SearchBox from '../search-box'

const ResourcesSearchBox = ({
  locale,
  displaySearchInput,
  categories,
  filter,
  setFilter
}) => {
  const onSearchTextUpdate = useCallback(
    search => {
      const nfilter = {
        ...filter,
        search
      }

      setFilter(nfilter)
    },
    [filter, setFilter]
  )
  const onCategoryClick = useCallback(
    selectedCategory => {
      const nfilter = {
        ...filter,
        category:
          selectedCategory?.slug === filter.category?.slug
            ? null
            : selectedCategory
      }

      setFilter(nfilter)
    },
    [filter, setFilter]
  )

  return (
    <SearchBox
      locale={locale}
      displaySearchInput={displaySearchInput}
      categories={categories}
      selectedCategory={filter.category}
      onSearchTextUpdate={onSearchTextUpdate}
      onCategoryClick={onCategoryClick}
    />
  )
}

export default ResourcesSearchBox
