import React, { useState } from 'react'
import { graphql } from 'gatsby'
import map from 'lodash/map'
import DatoCMSModel from '../../models/DatoCMSModel'

import Layout from '@c/layout'

import Hero from '@v/events/hero'
import EventList from '@v/events/list'
import Breadcrumb from '@c/breadcrumb'
import PageHeader from '@c/page-header'
import withLocation from '@/withLocation'

import './index.css'

const Page = ({ data, search }) => {
  const { page } = data
  const title = page.title
  const [events] = useState(() => map(data.events.edges, 'node'))
  const [links, setLinks] = useState([])
  const model = new DatoCMSModel(page)

  return (
    <Layout
      locale={page.locale}
      seo={{ ...model.getSeoComponentProps(null, { link: links }) }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb parentPages={[]} currentPage={title} />
          </div>
        </div>
      </div>

      {/* header*/}
      <div className="container">
        <div className="col-12 col-lg-6 offset-lg-3 ta-center">
          <PageHeader title={page.slogan} />
        </div>
      </div>

      <Hero
        title={page.heroTitle}
        heroYear={page.heroYear}
        startDate={page.startDate}
        endDate={page.endDate}
        hours={page.heroHours}
        address={page.heroAddress}
        buttonText={page.heroButtonText}
        url={page.heroUrl}
        locale={page.locale}
      />

      <EventList
        locale={page.locale}
        slug={page.slug}
        setLinks={setLinks}
        upcomingEventsTitle={page.upcomingEventsTitle}
        events={events}
        search={search}
      />
    </Layout>
  )
}

export default withLocation(Page)

export const query = graphql`
  query EventsPageQuery($locale: String) {
    page: datoCmsEventsPage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      slug
      locale

      title
      slogan

      heroTitle
      heroYear
      startDate
      endDate
      heroHours
      heroAddress
      heroButtonText
      heroUrl

      upcomingEventsTitle
    }

    events: allDatoCmsEvent(
      filter: { locale: { eq: $locale }, published: { eq: true } }
      sort: { fields: [startDate] }
    ) {
      edges {
        node {
          id
          title
          url
          address
          startDate
          endDate
          category {
            title
            slug
          }
          published
        }
      }
    }
  }
`
