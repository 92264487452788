import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import find from 'lodash/find'
import { useDebounce } from 'use-debounce'
import Icon from '@c/icon'
import CategoriesButton from '@c/categories-button'

import './index.css'

const query = graphql`
  query {
    settings: allDatoCmsSiteSetting {
      nodes {
        locale
        categoryDefault: searchBoxCategorySelectDefaultValue
      }
    }
  }
`

function SearchBox({
  locale,
  displaySearchInput = true,
  categories,
  selectedCategory,
  onCategoryClick,
  onSearchTextUpdate
}) {
  const {
    settings: { nodes: settingsNodes }
  } = useStaticQuery(query)
  const settings =
    find(settingsNodes, ['locale', locale]) ||
    find(settingsNodes, ['locale', 'en'])
  const [searchText, setSearchText] = useState(null)
  const [currentSearchText] = useDebounce(searchText, 1200)
  const [lastSearchText, setLastSearchText] = useState()
  let defaultSelectedCategory = null

  if (settings && settings.categoryDefault) {
    defaultSelectedCategory = { title: settings.categoryDefault }
  }

  useEffect(() => {
    if (currentSearchText === null) {
      return
    }

    // do not trigger an update if the search term isn't changed
    if (currentSearchText === lastSearchText) {
      return
    }

    onSearchTextUpdate(currentSearchText)
    setLastSearchText(currentSearchText)
  }, [currentSearchText, lastSearchText, setLastSearchText, onSearchTextUpdate])

  return (
    <div className="d-flex align-items-end flex-wrap flex-lg-nowrap">
      {displaySearchInput && (
        <div className="mr-24 py-16">
          <div className="d-flex align-items-center">
            <Icon className="search-img" name="search" />
            <input
              className="search-input"
              onChange={ev => setSearchText(ev.target.value)}
            />
          </div>
        </div>
      )}

      {categories && (
        <CategoriesButton
          selectedCategory={selectedCategory || defaultSelectedCategory}
          onClick={onCategoryClick}
          categories={categories}
          active={!!selectedCategory}
          locale={locale}
        />
      )}
    </div>
  )
}

export default SearchBox
