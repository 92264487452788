import React, { useState, useCallback, useEffect } from 'react'
import get from 'lodash/get'
import { DateTime } from 'luxon'

import filter from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import toPairs from 'lodash/fp/toPairs'
import groupBy from 'lodash/fp/groupBy'
import sortBy from 'lodash/fp/sortBy'

import RedLine from '@c/red-line'
import EventCard from '@c/event-card'
import usePrevNextLinks from '@h/use-prev-next-links'
import LoadMoreButton from '@c/common/LoadMoreButton'
import makeSlug from '@u/make-slug'
import ResourcesSearchBox from '@c/resources-search-box'
import useResourcesFilter from '@h/use-resources-filter'
import { extractCategories } from '@u/extract-unique-categories'

const EVENT_GROUPS_PER_PAGE = 2
const today = DateTime.now().startOf('day')
const makeEventGroups = locale => {
  const dateLocale = locale === 'en' ? 'en-US' : 'tr-TR'
  return flow(
    map(event => ({
      ...event,
      startDate: DateTime.fromFormat(event.startDate, 'yyyy-MM-dd').setLocale(
        dateLocale
      ),
      endDate: DateTime.fromFormat(event.endDate, 'yyyy-MM-dd').setLocale(
        dateLocale
      )
    })),
    filter(event => today < event.startDate),
    map(event => ({
      ...event,
      month:
        event.startDate.toLocaleString({ month: 'short' }) +
        (event.startDate.month === event.endDate.month
          ? ''
          : '-' + event.endDate.toLocaleString({ month: 'short' })),
      dates: `${
        event.startDate.day === event.endDate.day
          ? event.startDate.day
          : event.startDate.day + '-' + event.endDate.day
      }`
    })),
    sortBy(event => event.startDate),
    groupBy(event =>
      event.startDate.toLocaleString({ year: 'numeric', month: 'long' })
    ),
    toPairs,
    map(([key, events]) => {
      const sorted = flow(sortBy(event => event.endDate))(events)
      return [key, sorted]
    }),
    map(([key, events]) => [
      // add 'past' property to the group key
      {
        key,
        past: isPast(events)
      },
      events
    ])
  )
}

const EventGroup = ({ className, title, events, past }) => {
  return (
    <div className={className}>
      <h4>{title}</h4>
      <div className="row">
        {events.map(event => (
          <div key={event.id} className="col-12 col-lg-6 mt-32">
            <EventCard
              title={event.title}
              url={event.url}
              address={event.address}
              month={event.month}
              date={event.dates}
              isPast={past}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
const EventList = ({
  locale,
  slug,
  setLinks,

  upcomingEventsTitle,
  events,
  search
}) => {
  const [filter, setFilter] = useState({})
  const categories = extractCategories(events)
  const filtered = useResourcesFilter(events, filter, locale)
  const defaultPage = search.page ? Number(search.page) : 1
  const [eventGroups, setEventGroups] = useState(() =>
    makeEventGroups(locale)(filtered)
  )
  const [state, setState] = useState(() => ({
    page: defaultPage,
    visibleEventGroups: eventGroups.slice(
      (defaultPage - 1) * EVENT_GROUPS_PER_PAGE,
      defaultPage * EVENT_GROUPS_PER_PAGE
    )
  }))
  const onLoadMoreClick = useCallback(() => {
    const nPage = state.page + 1

    setState({
      ...state,
      page: nPage,
      visibleEventGroups: state.visibleEventGroups.concat(
        eventGroups.slice(
          (nPage - 1) * EVENT_GROUPS_PER_PAGE,
          nPage * EVENT_GROUPS_PER_PAGE
        )
      )
    })
  }, [eventGroups, state, setState])
  const hasMorePages =
    state.page < Math.ceil(eventGroups.length / EVENT_GROUPS_PER_PAGE)
  const links = usePrevNextLinks({
    data: eventGroups,
    slug: makeSlug.generic(locale, slug),
    pageSize: EVENT_GROUPS_PER_PAGE,
    page: state.page
  })

  useEffect(() => setLinks(links), [links, setLinks])
  useEffect(() => {
    setEventGroups(makeEventGroups(locale)(filtered))
  }, [filtered, setEventGroups, makeEventGroups, locale])
  useEffect(
    () =>
      setState({
        ...state,
        page: 1,
        visibleEventGroups: eventGroups.slice(0, EVENT_GROUPS_PER_PAGE)
      }),
    [eventGroups]
  )

  return (
    <div className="container mt-100">
      {/* title */}
      <header className="d-flex flex-wrap align-items-start align-items-lg-end justify-content-between">
        <div>
          <h3>{upcomingEventsTitle}</h3>
          <RedLine />
        </div>
        <div className="d-flex flex-wrap align-items-end justify-content-start justify-content-lg-end">
          <div className="mr-16">
            <ResourcesSearchBox
              locale={locale}
              categories={categories}
              filter={filter}
              setFilter={setFilter}
            />
          </div>
        </div>
      </header>

      {state.visibleEventGroups.map(([group, events]) => (
        <EventGroup
          key={group.key}
          className="mt-60"
          title={group.key}
          events={events}
          past={group.past}
        />
      ))}

      <div className="ta-center mt-60">
        <LoadMoreButton
          disabled={!hasMorePages}
          href={get(
            links.find(l => l.rel === 'next'),
            'href',
            '#'
          )}
          onClick={onLoadMoreClick}
        />
      </div>
    </div>
  )
}

function isPast(events) {
  const startOfMonth = DateTime.local()
    .startOf('month')
    .toMillis()
  const maxStartOfMonth = events.reduce(
    (acc, event) => Math.max(acc, event.endDate.startOf('month').toMillis()),
    0
  )

  return startOfMonth > maxStartOfMonth
}

export default EventList
