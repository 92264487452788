import React from 'react'
import PropTypes from 'prop-types'
import cb from 'classnames'

const EventCard = ({ url, title, address, month, date, isPast }) => (
  <div
    className={cb(
      'border-box py-32 py-lg-40 px-24 px-lg-32 d-flex align-items-center',
      {
        'bg-soft-blue': isPast
      }
    )}
  >
    <div className="flex-grow-1">
      <h5>
        <a href={url} className="link" target="_blank" rel="noreferrer">
          {title}
        </a>
      </h5>
      <p className="mt-8">{address}</p>
    </div>
    <div className="ta-right u-nowrap ml-16">
      <div className="h5">{month}</div>
      <div className="h3">{date}</div>
    </div>
  </div>
)

EventCard.defaultProps = {
  isPast: false
}

EventCard.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isPast: PropTypes.bool
}

export default EventCard
