import React, { useState, useRef, useEffect } from 'react'
import cn from 'classnames'
import find from 'lodash/find'
import { graphql, useStaticQuery } from 'gatsby'

import './index.css'

function CategoriesButton({
  categories,
  selectedCategory,
  active,
  onClick,
  locale = 'en'
}) {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(open => !open)
  const ref = useRef(null)
  const supportsTouch =
    (typeof window !== 'undefined' && 'ontouchstart' in window) ||
    (typeof navigator !== 'undefined' && navigator?.msMaxTouchPoints)
  useEffect(() => {
    const handleCloseMenu = event =>
      ref.current && !ref.current.contains(event.target) && setOpen(false)
    document.addEventListener('mousedown', handleCloseMenu)
    return () => document.removeEventListener('mousedown', handleCloseMenu)
  }, [ref])
  const [textAll, setTextAll] = useState('')
  const translations = useStaticQuery(graphql`
    query Translations {
      allDatoCmsTranslation {
        edges {
          node {
            locale
            filteringAll
          }
        }
      }
    }
  `).allDatoCmsTranslation.edges.map(o => o.node)
  useEffect(() => {
    const translation =
      find(translations, ['locale', locale]) ||
      find(translations, ['locale', 'en'])
    setTextAll(translation?.filteringAll)
  }, [locale])
  return (
    <div
      className="dropdown py-16"
      ref={ref}
      onMouseLeave={() => !supportsTouch && setOpen(false)}
    >
      <button
        className="drop-button d-flex align-items-center justify-content-around"
        onClick={handleOpen}
        onMouseEnter={() => !supportsTouch && setOpen(true)}
      >
        {selectedCategory?.title}
        <div className={cn('oval', active && 'oval-active')}></div>
      </button>
      <div
        className={cn(
          'dropdown-content category-dropdown-content',
          open && 'dropdown-active'
        )}
      >
        <button
          onClick={() => (onClick && onClick(null)) || (true && setOpen(false))}
        >
          {textAll}
        </button>
        {categories.map(category => {
          return (
            <button
              key={category.slug}
              onClick={() =>
                (onClick && onClick(category)) || (true && setOpen(false))
              }
            >
              {category.title}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default CategoriesButton
