import React from 'react'
import { DateTime } from 'luxon'

const Hero = ({
  title,
  heroYear,
  startDate,
  endDate,
  hours,
  address,
  buttonText,
  url,
  locale
}) => {
  let heroDate = [
    startDate,
    endDate
  ].filter(o => !!o).map(o =>  DateTime.fromFormat(o, 'yyyy-MM-dd'))



  const month = heroDate[0].toLocaleString({ month: 'long', locale })
  const dates = heroDate.map(o => o.day).join(' - ')
  const year = heroDate[0].year

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 mt-40 c-white ta-center">
          <div className="EventsHero d-flex flex-column align-items-center justify-content-center">
            <h4 className="d-inline-flex align-items-center">
              <span>{title}</span>
              <span className="h1 ml-8">{heroYear}</span>
            </h4>
            {locale === 'tr' && (
              <p className="mt-8 fs-large fw-text-bold">
                {dates} {month}, {year}, {hours}, {address}
              </p>
            )}
            {locale === 'en' && (
              <p className="mt-8 fs-large fw-text-bold">
                {month} {dates}, {year}, {hours}, {address}
              </p>
            )}
            <a
              className="EventsHero-cta btn btn--round btn--dark btn--shadow"
              href={url}
            >
              {buttonText}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
